export const environment = {
  production: true,
  plugins: [],
  appUrl: 'https://app.billytics.pro/',
  apiUrl: 'https://f5zqpil5wkm7i6qkmg3vprcvxy0qufqc.lambda-url.us-east-1.on.aws/api/v1',
  firebase: {
    config: {
      apiKey: 'AIzaSyABdKT4Hq6DQGQo_1KAYqA-iChzan1IXnQ',
      authDomain: 'billytics-prod.firebaseapp.com',
      projectId: 'billytics-prod',
      storageBucket: 'billytics-prod.firebasestorage.app',
      messagingSenderId: '132930824254',
      appId: '1:132930824254:web:c8f5c948ef03c5c5dfc96f',
    },
    vapidKey:
      'BGsuHGAb0EutPczzJ9M0yhg7_TzSsfKf07eJQDm8rgdrwGaadyQExY35oLWkWxfbBVeceopSjLqWGg1_Dk15SS4',
  },
  sentry: {
    dsn: 'https://114a1147d1a6e7e26f0fe7e9ddd113ca@o4508449151778816.ingest.us.sentry.io/4508449154334720',
    tracesSampleRate: 1.0,
    environment: 'prod',
  },
};
